import React, { useEffect, useState } from "react";
import { API } from "../../config";
import { getApiCallDynamic } from "../../server-call/event-api";

export default function Dashboard(props) {
  const list = [
    { name: "Yesterday's Count", amount: "52398" },
    { name: "Today's Count", amount: "109233" },
  ];

  let [data, setData] = useState(null);

  useEffect(() => {
    let param = {};
    getApiCallDynamic(param, API.requestCount).then((res) => {
      if (res.status === 200) {
        setData(res.data);
      }
    });
  }, []);

  useEffect(() => {
    const labels = ["January", "February", "March", "April", "May", "June"];
    const data = {
      labels: labels,
      datasets: [
        {
          label: "My First data set",
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgb(255, 99, 132)",
          data: [0, 10, 5, 2, 20, 30, 45],
        },
        {
          label: "My First data set2",
          backgroundColor: "blue",
          borderColor: "blue",
          data: [10, 15, 5, 12, 10, 30, 45],
        },
      ],
    };

    const config = {
      type: "line",
      data,
      options: {},
    };

    var myChart = new Chart(document.getElementById("myChart"), config);
  }, []);
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        {/* <!-- Counter --> */}

        <div className="col-12 col-md-4 p-2 p-md-5 border">
          <div className={`circle-counter3`}>
            <div className="inner">
              <h3>{"Total Dipping"}</h3>
              <h1>{data?.totalCount == null ? 0 : data?.totalCount}</h1>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 p-2 p-md-5 border">
          <div className={`circle-counter1`}>
            <div className="inner">
              <h3>{"Yesterday's Dipping"}</h3>
              <h1>{data?.yesterdayCount == null ? 0 : data?.yesterdayCount}</h1>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 p-2 p-md-5 border">
          <div className={`circle-counter2`}>
            <div className="inner">
              <h3>{"Today's Dipping"}</h3>
              <h1>{data?.todayCount == null ? 0 : data?.todayCount}</h1>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="col-12 col-md-4 p-2 p-md-5 border">
          <div className={`circle-counter4`}>
            <div className="inner">
              <h3>{"Total Amount"}</h3>
              <h1>{data?.totalAmount == null ? 0 : data?.totalAmount}</h1>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 p-2 p-md-5 border">
          <div className={`circle-counter5`}>
            <div className="inner">
              <h3>{"Yesterday's Amount"}</h3>
              <h1>
                {data?.yesterdayAmount == null ? 0 : data?.yesterdayAmount}
              </h1>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 p-2 p-md-5 border">
          <div className={`circle-counter6`}>
            <div className="inner">
              <h3>{"Today's Amount"}</h3>
              <h1>{data?.todayAmount == null ? 0 : data?.todayAmount}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-3">
        <div className="col-12 col-md-8 overflow-auto">
          <canvas id="myChart" width="400" height="150"></canvas>
        </div>
        <div className="col-12 col-md-4 p-3">
          <div className="d-counter">
            <p>Maximum</p>
            <h3>
              90<span>TPS</span>
            </h3>
          </div>
          <div className="d-counter">
            <p>Minimum</p>
            <h3>
              15<span>TPS</span>
            </h3>
          </div>
          <div className="d-counter">
            <p>Average</p>
            <h3>
              85<span>TPS</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
