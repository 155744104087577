import React, { useEffect, useState } from "react";
import CardView from "../../../../utils/components/card-view";
import { exportAsPDFtest } from "../../../../utils/pdf-genrator";

import { API } from "../../config";
import { getApiCallDynamic } from "../../server-call/event-api";

export default function MNPAPI() {
  let [a, setA] = useState(false);
  let [b, setB] = useState(false);
  let data = {
    status: 200,
    code: "81",
    mobile: "8801687894151",
    prefix: "88016",
    operator: "Airtel",
  };
  let [apiData, setApiData] = useState({
    masterKey: "",
  });
  useEffect(() => {
    getApiCallDynamic({}, API.ownInfo).then((res) => {
      if (res?.status === 200) {
        let data = res.data.dataList[0];
        setApiData(data);
      }
    });
  }, []);

  let globalDipingData = {
    status: 200,
    code: "47003",
    mobile: "4700388019XXXXXXXX",
    prefix: "88019",
    operator: "Banglalink",
  };

  let globalDipingDataFailure = {
    status: "5XX",
    code: "00000",
    mobile: "88019XXXXXXXX",
    prefix: "N",
    operator: "N",
  };
  let localDipingData = {
    status: 200,
    code: "91",
    mobile: "9188019XXXXXXXX",
    prefix: "88019",
    operator: "Banglalink",
  };

  let localDipingDataFailure = {
    status: "5XX",
    code: "00000",
    mobile: "88019XXXXXXXX",
    prefix: "N",
    operator: "N",
  };
  const handler = () => {
    exportAsPDFtest("table_pdf", "API_doc");
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-3">
          <div id="accordion">
            <CardView
              buttonName=" API Credential"
              value={a}
              set={setA}
              html={
                <div className="row">
                  <div className="col-12">
                    <form className="py-3 text-left">
                      <div className="row">
                        <div className="col-auto font-weight-bold">
                          <label>API Key:</label>
                        </div>
                        <div className="col-auto">
                          <label>{apiData.masterKey}</label>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-auto font-weight-bold">
                          <label>API URL</label>
                        </div>
                        <div className="col-auto">
                          <a
                            href="https://api.stripe.com/v1/charges -u sk_test_BQokikJOvBiI2HlWgH4olfQ2:"
                            target="_blank"
                          >
                            https://api.stripe.com/v1/charges -u
                            sk_test_BQokikJOvBiI2HlWgH4olfQ2:
                          </a>
                        </div>
                      </div> */}
                    </form>
                  </div>
                </div>
              }
            ></CardView>
            <CardView
              buttonName="API Documents"
              value={b}
              set={setB}
              html={
                <section>
                  <button
                    className="btn  btn-outline-primary btn-sm btn-light float-right"
                    onClick={handler}
                  >
                    <i className="fa fa-download pr-2"></i>
                    Download
                  </button>

                  <div className="container">
                    {/* Index */}
                    <div className="row pb-5">
                      <div className="col-12">
                        <h4>Index</h4>
                        <ul>
                          <li>
                            <a className="text-decoration-none" href="#dipping">
                              API Name: Dipping
                            </a>
                          </li>
                          <li>
                            <a className="text-decoration-none" href="#api-map">
                              Dipping API Response Code Map
                            </a>
                            <ul>
                              <li>
                                <a
                                  className="text-decoration-none"
                                  href="#prefix-list"
                                >
                                  Valid MSISDN Prefix List
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a className="text-decoration-none" href="#rn-list">
                              Valid Operator RN Code List
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div id="table_pdf">
                      {/* Local Dipping */}
                      <div className="row " id="dipping">
                        <div className="col-auto text-center m-auto">
                          <h3 className="my-3 font-weight-bold  ">
                            API Name: Dipping
                          </h3>

                          <table className="table text-center table-bordered table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Type</th>
                                <th scope="col">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>URL</td>
                                <td>
                                  <a href="#">
                                    {"https://[hostname]/mnp-checker/dipping"}
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td>Method</td>
                                <td>{"GET"}</td>
                              </tr>
                              <tr>
                                <td>
                                  Querry Parameters
                                  <span className="text-danger">*</span>
                                </td>
                                <td>{`apiKey=${apiData.masterKey}`}</td>
                              </tr>
                              <tr>
                                <td>
                                  Querry Parameters
                                  <span className="text-danger">*</span>
                                </td>
                                <td>{`number=[MSSISDN]`}</td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="table table-responsive table-bordered mt-5 table-striped">
                            {/* <!-- <thead>
                      <tr>
                        <th scope="col">Type</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead> --> */}
                            <tbody>
                              <tr>
                                <td className="align-middle">Curl Sample</td>
                                <td>
                                  <div className="text-break text-left">
                                    {`curl --location --request GET 'http://mnpapi.mnpspbd.com/mnp-checker/dipping?apikey=${apiData.masterKey}&number=<MSSISDN>' \
        --header 'Content-Type: text/xml'`}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          {/* Successful Response Data */}
                          <table
                            id="4"
                            className="table  table-bordered table-striped mt-5"
                          >
                            {/* <thead>
                      <tr>
                        <th scope="col">Type</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead> */}
                            <tbody>
                              <tr>
                                <td className="align-middle">
                                  Successful Response
                                </td>
                                <td>
                                  <div className="text-break text-left">
                                    <div>{"{"}</div>
                                    <div className="pl-3">{"data : [{"}</div>

                                    <div className="pl-3">
                                      {Object.keys(localDipingData).map(
                                        (key, i) => {
                                          return (
                                            <div className="pl-3">{`
                    "${key}": "${localDipingData[key]}" ${
                                              Object.keys(localDipingData)
                                                .length -
                                                1 ==
                                              i
                                                ? ""
                                                : ","
                                            }
                  `}</div>
                                          );
                                        }
                                      )}
                                      <div>{"}]"}</div>
                                    </div>
                                    <div>{"}"}</div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          {/* Failure Response Data */}
                          <table className="table  table-bordered table-striped mt-5">
                            {/* <thead>
                      <tr>
                        <th scope="col">Type</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead> */}
                            <tbody>
                              <tr>
                                <td className="align-middle">
                                  Failure Response
                                </td>
                                <td>
                                  <div className="text-break text-left">
                                    <div>{"{"}</div>
                                    <div className="pl-3">{"data : [{"}</div>

                                    <div className="pl-3">
                                      {Object.keys(localDipingDataFailure).map(
                                        (key, i) => {
                                          return (
                                            <div className="pl-3">{`
                    "${key}": "${localDipingDataFailure[key]}" ${
                                              Object.keys(
                                                localDipingDataFailure
                                              ).length -
                                                1 ==
                                              i
                                                ? ""
                                                : ","
                                            }
                  `}</div>
                                          );
                                        }
                                      )}
                                      <div>{"}]"}</div>
                                    </div>
                                    <div>{"}"}</div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {/* Dipping API Response Code Map */}
                      <div className="row py-5" id="api-map">
                        <div className="col-12 text-center m-auto">
                          <h3 className="my-3 font-weight-bold ">
                            Dipping API Response Code Map
                          </h3>

                          <table className="table text-center table-bordered table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Code</th>
                                <th scope="col">Description</th>
                                <th scope="col">Reason</th>
                                <th scope="col">Is Billable?</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>200</td>
                                <td>QUERY_SUCCESSFUL</td>
                                <td>
                                  Valid dipping request with ported MSISDN
                                </td>
                                <td>Y</td>
                              </tr>

                              <tr>
                                <td>501</td>
                                <td>SERVER_ERROR</td>
                                <td>For any kind of system failure</td>
                                <td>N</td>
                              </tr>

                              <tr>
                                <td>502</td>
                                <td>PARAMETER_MISSING</td>
                                <td>
                                  No API key was provided or No Mobile number
                                  was provided
                                </td>
                                <td>N</td>
                              </tr>

                              <tr>
                                <td>503</td>
                                <td>QUERY_FAILED</td>
                                <td>
                                  Request was validated but then system failure
                                  occurs during fetching poring information
                                </td>
                                <td>N</td>
                              </tr>

                              <tr>
                                <td>504</td>
                                <td>USER_INVALID</td>
                                <td>
                                  Client details was not found in system or
                                  client is inactive currently
                                </td>
                                <td>N</td>
                              </tr>

                              <tr>
                                <td>505</td>
                                <td>INVALID_IP</td>
                                <td>
                                  Request came from unregistered IP for provided
                                  client
                                </td>
                                <td>N</td>
                              </tr>

                              <tr>
                                <td>506</td>
                                <td>CREDIT_LESS</td>
                                <td>
                                  Valid request but client was out of balance
                                </td>
                                <td>N</td>
                              </tr>

                              <tr>
                                <td>507</td>
                                <td>PROVIDER_NOT_FOUND</td>
                                <td>
                                  Provided MSISDN length was not 13 or MSISDN
                                  prefix was invalid**
                                </td>
                                <td>N</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {/* Valid MSISDN Prefix List */}
                      <div className="row py-5" id="prefix-list">
                        <div className="col-auto">
                          {/* <h5 className="my-1 font-weight-bold ">**Valid MSISDN Prefix List</h5> */}

                          {/* <table className="table text-center table-bordered table-striped"> */}
                          <table className="table text-center table-responsive table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">**Valid MSISDN Prefix</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>88013, 88017</td>
                              </tr>
                              <tr>
                                <td>88014, 88019</td>
                              </tr>
                              <tr>
                                <td>88015</td>
                              </tr>
                              <tr>
                                <td>88016, 88018</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {/* Valid Operator RN Code List */}
                      <div className="row pt-5" id="rn-list">
                        <div className="col-12 text-center m-auto">
                          <h3 className="my-1 font-weight-bold ">
                            Valid Operator RN Code List
                          </h3>

                          <table className="table text-center table-bordered table-striped">
                            <thead>
                              <tr>
                                <th scope="col">MNO</th>
                                <th scope="col">Valid RN Code</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>GRAMEENPHONE (Grameenphone Ltd.)</td>
                                <td>71,31</td>
                              </tr>
                              <tr>
                                <td>
                                  BANGLALINK (Banglalink Digital Communications
                                  Ltd.)
                                </td>
                                <td>91,41</td>
                              </tr>
                              <tr>
                                <td>TELETALK (Teletalk Bangladesh Limited)</td>
                                <td>51</td>
                              </tr>
                              <tr>
                                <td>ROBI (Axiata Bangladesh Ltd.)</td>
                                <td>81</td>
                              </tr>
                              <tr>
                                <td>AIRTEL (Bharti Airtel Bangladesh Ltd.)</td>
                                <td>81</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              }
            ></CardView>
          </div>
        </div>
      </div>
    </div>
  );
}
